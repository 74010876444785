<ng-container *transloco="let t">
    <ng-container *ngIf="mode === 'input'">
        <bazis-input-default
            [formControl]="dateValue"
            [tooltipKey]="tooltipKey"
            [tooltipParams]="tooltipParams"
            [tooltipSettings]="tooltipSettings"
            [titleKey]="titleKey"
            [titleParams]="titleParams"
            [required$]="required$"
            [noteKey]="noteKey"
            [noteParams]="noteParams"
            [placeholderKey]="placeholderKey"
            [placeholder]="placeholder"
            [validationErrorMessages]="validationErrorMessages"
            [hasEraser]="hasEraser"
            [maskSettings]="maskSettings"
            [sizeControl]="sizeControl"
        >
            <ng-content></ng-content>
            <bazis-form-error
                *ngIf="dateValue.status !== 'INVALID'"
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="ngControl.control"
            ></bazis-form-error>
        </bazis-input-default>
    </ng-container>
    <ng-container *ngIf="mode === 'picker'">
        <div
            *ngIf="{
            required: required$ | async,
        } as data"
            #inputDate
            clickOutside
            (clickOutside)="clickPopperOutside()"
            [excludeElement]="calendar"
        >
            <div
                class="bazis-control"
                [class]="'bazis-control ' + cssClassSizeControl"
                [ngClass]="{
                    'bazis-control--invalid': ngControl.control.invalid,
                    'bazis-control--disabled': disabled
                }"
            >
                <bazis-label
                    [isMajor]="hasTitleMajor"
                    [isEmptyLabel]="isEmptyLabel"
                    [title]="title || t(titleKey, titleParams)"
                    [required]="data.required"
                >
                    <bazis-interrogation
                        *ngIf="tooltipKey"
                        [slot]="tooltipSettings?.targetSide"
                        iconSize="xs"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipOffset]="[-8, 4]"
                        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                        [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                        [icon]="icons.interrogation"
                        [type]="interrogationType"
                    ></bazis-interrogation>
                </bazis-label>

                <div
                    class="bazis-control__field-group bh-pointer"
                    [class.bazis-control__field-group--disabled]="
                        ngControl.control.disabled || disabled
                    "
                    (click)="openCalendar($event)"
                >
                    <bazis-control-field-start
                        *ngIf="placeIcon === 'left'"
                        [icon]="icons.date"
                    >
                    </bazis-control-field-start>
                    <div class="bazis-control__field-wrapper">
                        <div
                            *ngIf="dateValue.value; else tplEmptyField"
                            class="bazis-control__field"
                        >
                            {{ dateValue.value }}
                        </div>
                        <ng-template #tplEmptyField>
                            <div class="bazis-control__field bazis-text_placeholder">
                                {{
                                    placeholder
                                        ? placeholder
                                        : placeholderKey
                                        ? t(placeholderKey)
                                        : ''
                                }}
                            </div>
                        </ng-template>
                    </div>

                    <bazis-control-field-end
                        [icon]="placeIcon === 'right' ? icons.date : ''"
                        [canEraserShow]="hasEraser"
                        [value]="ngControl.control.value"
                        (erased)="onClear()"
                    >
                    </bazis-control-field-end>
                </div>

                <div class="bazis-control__error">
                    <bazis-form-error
                        [validationErrorMessages]="validationErrorMessages"
                        [formControl]="ngControl.control"
                    ></bazis-form-error>

                    <!-- для ошибок родительского контрола
                (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
                    <ng-content></ng-content>
                </div>
                <div
                    *ngIf="noteKey"
                    class="bazis-control__note"
                >
                    {{ t(noteKey, noteParams) }}
                </div>
            </div>

            <div
                #calendar
                class="bh-popper-z-index"
                [hidden]="!showCalendar"
                (click)="clickPopper()"
            >
                <bazis-datepicker
                    *ngIf="showCalendar"
                    [value]="calendarValue"
                    (valueChange)="setValueFromCalendar($event)"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [formatOutput]="displayFormat"
                    [startYear]="startYear"
                    [disabledIntervals]="disabledIntervals"
                    [marks$]="marks$"
                    [viewConfig]="datapickerViewConfig"
                    [visibleAllYears]="visibleAllYears"
                    [canToggleDay]="canToggleDay"
                ></bazis-datepicker>
            </div>
        </div>
    </ng-container>
</ng-container>
